import { observer } from 'mobx-react'

import { useActiveUser } from 'Hooks'

import { InstantsearchProvider } from 'Portal/src/providers'
import { InstantsearchIndexProvider } from 'Portal/src/providers/InstantsearchIndexProvider/InstantsearchIndexProvider'

import { RentalsContent } from './Rentals.content'

export const RentalsInstantsearch = observer(() => {
  const { activeEmployee, activeOrganisation } = useActiveUser()
  const organisationId =
    activeEmployee?.organisation?.id ?? activeOrganisation!.id

  return (
    <InstantsearchIndexProvider indexName="rentals">
      <InstantsearchProvider
        instantSearchConfig={{
          filters: `rental.organisation_id:${organisationId} && rental.start_at:>0 && rental.stocklist_item_id:>0`,
        }}
        sortBy="rental.id:desc"
      >
        <RentalsContent />
      </InstantsearchProvider>
    </InstantsearchIndexProvider>
  )
})
