/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum CaseItemStatus {
  done = "done",
  in_progress = "in_progress",
  new = "new",
  pending = "pending",
}

export enum CaseItemType {
  assign_device = "assign_device",
  assign_device_to_employee = "assign_device_to_employee",
  assign_stocklist_items_to_depot = "assign_stocklist_items_to_depot",
  assign_stocklist_items_to_depot_bucket = "assign_stocklist_items_to_depot_bucket",
  bulk_balb = "bulk_balb",
  bulk_hr_import = "bulk_hr_import",
  bulk_new_device = "bulk_new_device",
  bulk_order_new_device = "bulk_order_new_device",
  bulk_phone_plan = "bulk_phone_plan",
  bulk_sales = "bulk_sales",
  bulk_sourcing = "bulk_sourcing",
  bulk_stocklist_condition_update = "bulk_stocklist_condition_update",
  bulk_user = "bulk_user",
  bulk_user_bucket = "bulk_user_bucket",
  bundle_early_upgrade_device = "bundle_early_upgrade_device",
  bundle_new_device = "bundle_new_device",
  bundle_rental_complete_upgrade_device = "bundle_rental_complete_upgrade_device",
  bundle_repair_upgrade = "bundle_repair_upgrade",
  bundle_upgrade_device = "bundle_upgrade_device",
  buy_and_leaseback = "buy_and_leaseback",
  buy_and_leaseback_bucket = "buy_and_leaseback_bucket",
  cancel_device = "cancel_device",
  cancel_phone_plan = "cancel_phone_plan",
  change_device = "change_device",
  change_phone_plan = "change_phone_plan",
  create_employee = "create_employee",
  create_sale_for_stocklist_item = "create_sale_for_stocklist_item",
  depot_device_cleaning = "depot_device_cleaning",
  device_shipping = "device_shipping",
  early_upgrade_device = "early_upgrade_device",
  employee_lost_device = "employee_lost_device",
  exchange_device = "exchange_device",
  general = "general",
  general_bucket = "general_bucket",
  general_purchases = "general_purchases",
  lost_device = "lost_device",
  new_device = "new_device",
  new_device_bucket = "new_device_bucket",
  new_employee = "new_employee",
  new_employee_bucket = "new_employee_bucket",
  new_phone_plan = "new_phone_plan",
  new_phone_plan_bucket = "new_phone_plan_bucket",
  new_tariff_bucket = "new_tariff_bucket",
  order_devices_for_depot = "order_devices_for_depot",
  purchase_device = "purchase_device",
  rental_complete_upgrade_device = "rental_complete_upgrade_device",
  repair_device = "repair_device",
  repair_device_bucket = "repair_device_bucket",
  repair_upgrade = "repair_upgrade",
  request = "request",
  request_bucket = "request_bucket",
  return_of_device = "return_of_device",
  set_stocklist_items_status = "set_stocklist_items_status",
  set_stocklist_items_status_bucket = "set_stocklist_items_status_bucket",
  shipping = "shipping",
  shipping_bucket = "shipping_bucket",
  sourcing = "sourcing",
  sourcing_bucket = "sourcing_bucket",
  sourcing_request = "sourcing_request",
  sourcing_request_bucket = "sourcing_request_bucket",
  upgrade_device = "upgrade_device",
  upgrade_device_bucket = "upgrade_device_bucket",
  vending_machine_device = "vending_machine_device",
}

export enum ContactType {
  billing = "billing",
  normal = "normal",
  shipping = "shipping",
  technical = "technical",
}

export enum Currency {
  CHF = "CHF",
  EUR = "EUR",
  GBP = "GBP",
  USD = "USD",
}

export enum InvoiceStatus {
  bounced = "bounced",
  draft = "draft",
  open = "open",
  paid = "paid",
  void = "void",
  write_off = "write_off",
}

export enum Language {
  de = "de",
  en = "en",
  fr = "fr",
}

export enum OrderAddressType {
  billing = "billing",
  shipping = "shipping",
}

export enum OrderStatus {
  cancel = "cancel",
  done = "done",
  new = "new",
  paid = "paid",
  payment_failed = "payment_failed",
  payment_revoke = "payment_revoke",
  sync_done = "sync_done",
  sync_failed = "sync_failed",
}

export enum OrderType {
  earlyUpgrade = "earlyUpgrade",
  lostDevice = "lostDevice",
  newDevice = "newDevice",
  repairUpgrade = "repairUpgrade",
  upgrade = "upgrade",
}

/**
 * enums
 */
export enum ReplacementType {
  depot = "depot",
  everphone = "everphone",
  none = "none",
}

export enum ReportStatus {
  done = "done",
  error = "error",
  process = "process",
}

export enum ReportType {
  employees = "employees",
  orders = "orders",
  rentals = "rentals",
  return_case = "return_case",
}

export enum ReturnCaseStatus {
  action_needed = "action_needed",
  blocked = "blocked",
  blocked_mdm_lock = "blocked_mdm_lock",
  blocked_user_lock = "blocked_user_lock",
  blocked_user_mdm_lock = "blocked_user_mdm_lock",
  broken_device_closed = "broken_device_closed",
  device_confirmed = "device_confirmed",
  device_sold_closed = "device_sold_closed",
  done = "done",
  in_customs = "in_customs",
  internal_correction_closed = "internal_correction_closed",
  lost_device_closed = "lost_device_closed",
  open = "open",
  package_delivered = "package_delivered",
  paused = "paused",
  received = "received",
  rejected = "rejected",
  return_depot = "return_depot",
}

export enum ReturnCaseSubstatus {
  delivery_problem = "delivery_problem",
  have_fp_check_locked = "have_fp_check_locked",
  in_customer_lock = "in_customer_lock",
  in_mdm = "in_mdm",
  in_mdm_and_customer_lock = "in_mdm_and_customer_lock",
  lost_during_return_delivery = "lost_during_return_delivery",
  other = "other",
  return_blocked_by_customs = "return_blocked_by_customs",
  return_stolen = "return_stolen",
}

export interface AddressInput {
  _label?: string | null;
  name?: string | null;
  nameAddition?: string | null;
  street?: string | null;
  streetAddition?: string | null;
  zipcode?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface AssignDeviceToEmployeeInput {
  organisationID: string;
  userID: string;
  employeeID: string;
  stocklistItem: string;
  message?: string | null;
}

export interface CancelDeviceCaseItemInput {
  organisationID: string;
  requestingUserID: string;
  rentalID: string;
  message?: string | null;
}

export interface Device {
  id: string;
  quantity?: number | null;
  customerLabel?: string | null;
  customClientReference?: string | null;
  poRcn?: string | null;
  billingIdentifier?: string | null;
}

export interface EmployeeLostDeviceCaseItemInput {
  organisationID: string;
  requestingUserID: string;
  rentalID: string;
  stockListItemID?: string | null;
  employeeID?: string | null;
  replacementType: ReplacementType;
  shippingAddress?: AddressInput | null;
  message?: string | null;
}

export interface EmployeeOnboardingRequest {
  allowLogin: boolean;
  organisationID: string;
  officeID?: string | null;
  costCenterID?: string | null;
  rentCategoryTierID?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  externalReference?: string | null;
  language?: Language | null;
  newCostCenter?: string | null;
}

export interface EmployeeUpdateInput {
  id: string;
  organisationID: string;
  allowLogin?: boolean | null;
  officeID?: string | null;
  costCenterID?: string | null;
  rentCategoryTierID?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  externalReference?: string | null;
  language?: Language | null;
  newCostCenter?: string | null;
}

export interface ExchangeDeviceInput {
  message?: string | null;
  currentRentalID: string;
  replacementStocklistItemID: string;
}

export interface Filter {
  enableLimitations: boolean;
  employeeID?: string | null;
  organisationID: string;
  productTypeByDeviceDefinitionID?: string | null;
}

export interface GenerateReportInput {
  reportType: ReportType;
  requestUserID: string;
  requestOrganizationID: string;
  requestDate: any;
  filter?: string | null;
}

export interface LegacyUpgradeDeviceCaseItemInput {
  message?: string | null;
  rentalID: string;
}

export interface NewDeviceCaseItemInput {
  organisationID: string;
  requestingUserID: string;
  device: Device;
  accessories?: (Device | null)[] | null;
  phonePlanID?: string | null;
  employeeID?: string | null;
  externalReference?: string | null;
  shippingAddress: AddressInput;
  comment?: string | null;
  externalID?: string | null;
  rentCategoryID: string;
  vendingMachineID?: string | null;
  addressID?: string | null;
}

export interface OfferOrderAddressInput {
  label?: string | null;
  type: OrderAddressType;
  name?: string | null;
  nameAddition?: string | null;
  street?: string | null;
  streetAddition?: string | null;
  zipcode?: string | null;
  city?: string | null;
  country?: string | null;
}

export interface OfferOrderInput {
  organisationId: string;
  employeeId?: string | null;
  externalID?: string | null;
  shippingAddress: OfferOrderAddressInput;
  remark?: string | null;
  items: OfferOrderItemsInput[];
  offerId: string;
  orderType: OrderType;
  previousRentalID?: string | null;
  addressID?: string | null;
  vendingMachineID?: string | null;
}

export interface OfferOrderItemsInput {
  quantity: number;
  offerItemId: string;
  customerLabel?: string | null;
  customClientReference?: string | null;
  poRcn?: string | null;
  billingIdentifier?: string | null;
}

export interface OfferOrdersInput {
  requestingUserID: string;
  orders: OfferOrderInput[];
}

export interface PhonePlanCaseItemInput {
  name?: string | null;
  quantity: number;
  employees: string[];
  userId: string;
  organisationID: string;
  message?: string | null;
}

export interface PurchaseDeviceCaseItemInput {
  rentalID: string;
  email?: string | null;
  message?: string | null;
}

export interface ReplaceBrokenDeviceInput {
  organisationID: string;
  userID: string;
  rentalID: string;
  shippingAddress?: AddressInput | null;
  message?: string | null;
  replacementType: ReplacementType;
  stocklistItemID?: string | null;
}

export interface ReturnDeviceInput {
  message?: string | null;
  rentalID: string;
  returnDepotID?: string | null;
}

export interface UpdateUserInput {
  id: string;
  language: Language;
}

export interface UpgradeDeviceCaseItemInput {
  organisationID: string;
  requestingUserID: string;
  device: Device;
  accessories?: (Device | null)[] | null;
  phonePlanID?: string | null;
  employeeID?: string | null;
  externalReference?: string | null;
  shippingAddress: AddressInput;
  comment?: string | null;
  externalID?: string | null;
  rentCategoryID: string;
  previousRentalID: string;
  previousStocklistItemID?: string | null;
  isRepairUpgrade?: boolean | null;
  addressID?: string | null;
}

export interface VendingMachineAvailabilityInput {
  orgID: string;
  DeviceDefinitionIDs?: string[] | null;
}

export interface createUpdateReturnCaseTrackingInfoInput {
  id?: string | null;
  returnCaseId?: string | null;
  userId: string;
  organisationID: string;
  trackingReference?: string | null;
  carrier?: string | null;
}

export interface orderTermsInput {
  organisationID: string;
  offerID: string;
  hasItemsWithCoPayment?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
