import { isEmpty } from 'lodash-es'
import { action, computed, makeObservable, observable } from 'mobx'

import { OrderItemTextField } from 'Modules/order/components/OrderSummary/OrderSummary.types'
import { OrderFlowTypesEnum } from 'Modules/order/types/orderFlow.types'

import { ShippingAddressFormSchema } from 'Components/ShippingAddressForm/ShippingAddressForm.types'

import { PAGINATION } from 'Constants/constants'
import { mutationNewDeviceCaseItem_createNewDeviceCaseItem_subCaseItems } from 'Constants/graphql/mutations/__generated__/mutationNewDeviceCaseItem'
import {
  getEmployeeContact,
  getEmployeeContactVariables,
} from 'Constants/graphql/queries/employee/__generated__/getEmployeeContact'
import { GET_EMPLOYEE_CONTACT } from 'Constants/graphql/queries/employee/getEmployeeContact'
import {
  getCatalog,
  getCatalogVariables,
} from 'Constants/graphql/queries/feature/__generated__/getCatalog'
import {
  getDeviceAccessories,
  getDeviceAccessoriesVariables,
} from 'Constants/graphql/queries/feature/__generated__/getDeviceAccessories'
import {
  getVendingMachineData,
  getVendingMachineDataVariables,
} from 'Constants/graphql/queries/feature/__generated__/getVendingMachineData'
import { GET_CATALOG } from 'Constants/graphql/queries/feature/GetCatalog'
import { GET_DEVICE_ACCESSORIES } from 'Constants/graphql/queries/feature/GetDeviceAccessories'
import { GET_VENDING_MACHINE_DATA } from 'Constants/graphql/queries/feature/GetVendingMachineData'

import ApolloLoader from 'Portal/src/ApolloLoader'
import {
  productOffersDeviceMapper,
  rentCategoriesDeviceMapper,
} from 'Portal/src/mappers/orderDeviceMapper/orderDevice.mapper'
import {
  OrderDevice,
  OrderDeviceMappedResponse,
} from 'Portal/src/mappers/orderDeviceMapper/orderDevice.types'
import { calculateNetPrices } from 'Portal/src/utilities/calculateNetPrices/calculateNetPrices.util'

import type { RootStore } from '..'
import { NetPriceCalculatorDefaultValues } from './orderStore.constants'
import {
  EmployeeContactData,
  NetPriceCalculatorInput,
  OrderAddressFields,
  OrderFilters,
  OrderFilterTypeEnum,
  OrderLimitations,
  OrderSortCriteriaEnum,
  OrderStepEnum,
  OriginRoute,
  VendingMachinesData,
} from './orderStore.types'
import {
  countTotalPrice,
  getOrderAddress,
  skipAccessories,
  toggleAccessory,
} from './orderStore.utils'
import {
  adjustChosenAccessoriesLimitationRules,
  adjustChosenDeviceLimitationRules,
  getLimitationRules,
} from './orderStoreLimitationRules.utils'

export class OrderStore {
  currentStep: OrderStepEnum | null = null

  orderDevices: OrderDeviceMappedResponse | null = null

  chosenDevice: OrderDevice | null = null

  chosenAccessories: Map<string, OrderDevice> = new Map()

  chosenShippingAddress: ShippingAddressFormSchema | null = null

  filters: OrderFilters = new Map([
    [OrderFilterTypeEnum.Colors, new Set<string>()],
    [OrderFilterTypeEnum.Manufacturers, new Set<string>()],
    [OrderFilterTypeEnum.Storages, new Set<string>()],
    [OrderFilterTypeEnum.ProductTypes, new Set<string>()],
  ])

  sortCriteria: OrderSortCriteriaEnum = OrderSortCriteriaEnum.Default

  accessories: OrderDevice[] | null = null

  officeAddress: OrderAddressFields | null = null

  fetchError: boolean = false

  mutationError = false

  isAccessoriesLoading = false

  isCYOD = false

  isLoading = true

  isProductOffer = false

  isOrderDisabled = false

  isUpgradeFromProlongingPage = false

  orderedDevices:
    | mutationNewDeviceCaseItem_createNewDeviceCaseItem_subCaseItems[]
    | number
    | null = null

  orderEmployeeName: string | null = null

  netPriceCalculator: NetPriceCalculatorInput = NetPriceCalculatorDefaultValues

  limitationRules: OrderLimitations | null = null

  employeeContact: EmployeeContactData = {
    data: null,
    isLoading: true,
  }

  vendingMachinesData: VendingMachinesData = {
    data: null,
    isLoading: true,
  }

  originRoute: OriginRoute = null

  previewColumnDevice: OrderDevice | null = null

  orderExternalId: string | null = null

  constructor(private readonly store: RootStore) {
    makeObservable(this, {
      accessories: observable,
      chosenAccessories: observable,
      chosenDevice: observable,
      chosenShippingAddress: observable,
      clearAllChoices: action,
      clearChosenAccessories: action,
      clearChosenDeviceLimitationRules: action,
      clearChosenDeviceWithAccessories: action,
      clearFilters: action,
      currentStep: observable,
      employeeContact: observable,
      fetchCatalog: action,
      fetchEmployeeContact: action,
      fetchError: observable,
      fetchOrderAccessories: action,
      filters: observable,
      getTotalPrice: computed,
      isAccessoriesLoading: observable,
      isCYOD: observable,
      isLoading: observable,
      isOrderDisabled: observable,
      isUpgradeFromProlongingPage: observable,
      limitationRules: observable,
      mutationError: observable,
      netPriceCalculator: observable,
      officeAddress: observable,
      orderDevices: observable,
      orderEmployeeName: observable,
      orderExternalId: observable,
      orderedDevices: observable,
      originRoute: observable,
      previewColumnDevice: observable,
      resetAll: action,
      setAccessories: action,
      setAccessoryQuantity: action,
      setAccessoryTextField: action,
      setAddonDeviceTextField: action,
      setChosenAccessory: action,
      setChosenDevice: action,
      setChosenDeviceQuantity: action,
      setChosenDeviceTextField: action,
      setChosenShippingAddress: action,
      setCurrentStep: action,
      setEmployeeContact: action,
      setExternalId: action,
      setFetchError: action,
      setFilters: action,
      setIsCYOD: action,
      setIsLoading: action,
      setIsOrderDisabled: action,
      setIsUpgradeFromProlongingPage: action,
      setLimitationRules: action,
      setMutationError: action,
      setNetPriceCalculator: action,
      setOfficeAddress: action,
      setOrderDevices: action,
      setOrderEmployeeName: action,
      setOrderedDevices: action,
      setOriginRoute: action,
      setSortCriteria: action,
      setVendingMachineData: action,
      shouldSkipAccessories: computed,
      sortCriteria: observable,
      toggleAccessorySelection: action,
      vendingMachinesData: observable,
    })
  }

  fetchCatalog = async (
    orderFlowType: `${OrderFlowTypesEnum}`,
    employeeId: string | undefined,
    isOrganisation: boolean,
  ) => {
    const { apolloClient } = ApolloLoader
    const { organisation } = this.store.organisationStore
    const { employee } = this.store.employeeStore

    const isEmployee = !!(
      (!isOrganisation && employee?.id) ||
      (isOrganisation && employeeId)
    )

    const organisationId =
      isOrganisation && !isEmployee
        ? organisation?.id.toString()
        : employee?.organisation?.id.toString() || organisation?.id.toString()

    if (!apolloClient) return

    this.setIsLoading(true)
    this.setIsOrderDisabled(false)

    try {
      const { rentalDetails } = this.store.rentalStore
      const { portalConfig } = this.store.portalConfigStore

      if (organisationId) {
        const shouldUseLimitationRules =
          (orderFlowType === OrderFlowTypesEnum.NEW_ORDER && !isOrganisation) ||
          (portalConfig?.enableProductOffers && !isOrganisation) ||
          false

        const onlyShowDevicesWithSameProductType =
          ((orderFlowType === OrderFlowTypesEnum.UPGRADE ||
            orderFlowType === OrderFlowTypesEnum.EARLY_UPGRADE ||
            orderFlowType === OrderFlowTypesEnum.REPAIR_UPGRADE) &&
            isEmployee) ||
          orderFlowType === OrderFlowTypesEnum.LOST_REPLACEMENT

        const response = await apolloClient.query<
          getCatalog,
          getCatalogVariables
        >({
          query: GET_CATALOG,
          variables: {
            after: PAGINATION.AFTER,
            filter: {
              enableLimitations:
                orderFlowType === OrderFlowTypesEnum.LOST_REPLACEMENT
                  ? false
                  : shouldUseLimitationRules,
              organisationID: organisationId,
              ...(isEmployee && {
                employeeID: employee?.id.toString() || employeeId,
              }),
              productTypeByDeviceDefinitionID:
                onlyShowDevicesWithSameProductType
                  ? rentalDetails?.rental.stocklistItem?.deviceDefinition?.id
                  : undefined,
            },
            first: PAGINATION.ITEMS_PER_PAGE,
            organisationId: Number(organisationId),
          },
        })

        const { catalog } = response.data

        const disableNewOrderForEmployee =
          isEmployee &&
          orderFlowType === OrderFlowTypesEnum.NEW_ORDER &&
          !catalog?.employee?.actions.showOrderNewDeviceAction

        if (disableNewOrderForEmployee || catalog === null) {
          this.setIsOrderDisabled(true)
        }

        if (catalog?.offers) {
          this.setIsProductOffer(true)
          this.setOrderDevices(
            productOffersDeviceMapper(catalog?.offers.offerConfigGroups),
          )
        } else {
          this.setIsProductOffer(false)
          this.setOrderDevices(
            rentCategoriesDeviceMapper(
              catalog?.rentCategories?.rentcategories || null,
            ),
          )
        }

        if (
          catalog?.employee?.contact.firstName ||
          catalog?.employee?.contact.lastName
        ) {
          this.setOrderEmployeeName(
            `${catalog?.employee?.contact.firstName ?? ''} ${
              catalog?.employee?.contact.lastName ?? ''
            }`,
          )
        }

        const address = getOrderAddress(
          isOrganisation,
          isEmployee,
          organisation,
          employee,
        )

        if (!address && !portalConfig?.shipDeviceToCustomAddress) {
          this.setFetchError(true)
        } else if (address) {
          this.setOfficeAddress(address as OrderAddressFields)
        }

        const limitationRules = getLimitationRules(
          organisation,
          employee,
          orderFlowType === OrderFlowTypesEnum.NEW_ORDER
            ? null
            : rentalDetails?.rental.stocklistItem?.deviceDefinition
                ?.productType,
        )

        if (limitationRules) {
          this.setLimitationRules(limitationRules)
        }
      }
    } catch (error) {
      this.setFetchError(true)
    } finally {
      this.setIsLoading(false)
    }
  }

  fetchOrderAccessories = async () => {
    const { apolloClient } = ApolloLoader

    if (!apolloClient || !this.chosenDevice?.rentCategoryId) return

    this.setIsAccessoriesLoading(true)

    try {
      const response = await apolloClient.query<
        getDeviceAccessories,
        getDeviceAccessoriesVariables
      >({
        query: GET_DEVICE_ACCESSORIES,
        variables: { id: this.chosenDevice.rentCategoryId },
      })

      const deviceDefinitions =
        response.data.rentCategory.deviceDefinitions?.deviceDefinitions
      let fetchedAccessories: OrderDevice[] = []

      if (deviceDefinitions) {
        const accessories = deviceDefinitions.find(
          deviceDefinition =>
            deviceDefinition.id === this.chosenDevice?.deviceDefinition.id,
        )?.accessories

        accessories?.accessories?.forEach((accessory, index) => {
          fetchedAccessories = [
            ...fetchedAccessories,
            {
              basisAmount: accessory.rentCategory.displayedBasisAmount,
              calculatedNet: 0,
              copaymentGross: accessory.rentCategory.copaymentGross,
              copaymentNet: accessory.rentCategory.copaymentNet,
              deviceDefinition: accessory.deviceDefinition,
              forcePriceHide: accessory.rentCategory.forcePriceHide,
              id: index.toString(),
              minimumContractDuration:
                this.chosenDevice?.minimumContractDuration || 0,
              offerId: accessory.rentCategory.id || '',
              quantity: 1,
              rentCategoryId: accessory.rentCategory.id || '',
            },
          ]
        })

        this.setAccessories(fetchedAccessories)
      }

      if (this.netPriceCalculator.salaryValue) {
        calculateNetPrices(this)
      }

      this.setIsAccessoriesLoading(false)
    } catch (error) {
      this.setIsAccessoriesLoading(false)
    }
  }

  setOrderDevices = (orderDevices: OrderDeviceMappedResponse | null) => {
    this.orderDevices = orderDevices
  }

  setChosenDevice = async (device: OrderDevice | null) => {
    const { chosenDevice, limitationRules, setLimitationRules } = this

    if (device) {
      const newLimitationRules = adjustChosenDeviceLimitationRules(
        limitationRules,
        device,
        'remove',
      )

      if (newLimitationRules) {
        setLimitationRules(newLimitationRules)
      }
    }

    if (chosenDevice) {
      const newLimitationRules = adjustChosenDeviceLimitationRules(
        limitationRules,
        device,
        'add',
      )

      if (newLimitationRules) {
        setLimitationRules(newLimitationRules)
      }
    }

    this.chosenDevice = device

    // hard clear accessories before loading new
    this.setAccessories(null)

    if (device && isEmpty(device.optionalDevices)) {
      await this.fetchOrderAccessories()
    }

    if (device && !isEmpty(device.optionalDevices)) {
      this.setAccessories(device.optionalDevices || null)
    }
  }

  setChosenAccessory = (accessory: OrderDevice) => {
    this.chosenAccessories.set(accessory.id, accessory)
  }

  setPreviewColumnDevice = async (device: OrderDevice | null) => {
    this.previewColumnDevice = device
  }

  setChosenDeviceQuantity = (quantity: number) => {
    const { chosenDevice, chosenAccessories } = this

    if (chosenDevice) {
      const addonDevices = chosenDevice.addonDevices?.map(addonDevice => ({
        ...addonDevice,
        quantity,
      }))

      chosenDevice.quantity = quantity
      chosenDevice.addonDevices = addonDevices

      if (chosenAccessories.size > 0) {
        chosenAccessories.forEach(chosenAccessory => {
          if (chosenAccessory.quantity > quantity) {
            this.setAccessoryQuantity(quantity, chosenAccessory)
          }

          // First tow conditions mean that accessory quantity input can only be 0
          // or main device quantity (maxQuantity)
          if (
            !chosenAccessory.isCreateSeparateRental &&
            chosenAccessory.basisAmount + chosenAccessory.copaymentNet > 0 &&
            chosenAccessory.quantity > 0 &&
            chosenAccessory.quantity !== quantity
          ) {
            this.setAccessoryQuantity(quantity, chosenAccessory)
          }
        })
      }
    }
  }

  setChosenDeviceTextField = (field: OrderItemTextField, value: string) => {
    const { chosenDevice } = this

    if (chosenDevice) {
      chosenDevice[field] = value
    }
  }

  clearChosenAccessories = () => {
    const { chosenAccessories, limitationRules, setLimitationRules } = this

    if (this.isProductOffer) {
      for (const [key, accessory] of chosenAccessories) {
        if (!accessory.isCreateSeparateRental) chosenAccessories.delete(key)
      }
    }

    const newLimitationRules = adjustChosenAccessoriesLimitationRules(
      limitationRules,
      chosenAccessories,
      'add',
    )

    if (newLimitationRules) {
      setLimitationRules(newLimitationRules)
    }

    chosenAccessories.clear()
  }

  clearChosenDeviceWithAccessories = () => {
    const {
      chosenDevice,
      chosenAccessories,
      limitationRules,
      setLimitationRules,
    } = this

    let newLimitationRules = adjustChosenDeviceLimitationRules(
      limitationRules,
      chosenDevice,
      'add',
    )

    newLimitationRules = adjustChosenAccessoriesLimitationRules(
      limitationRules,
      chosenAccessories,
      'add',
    )

    if (newLimitationRules) {
      setLimitationRules(newLimitationRules)
    }

    this.setChosenDevice(null)
    chosenAccessories.clear()
  }

  clearChosenDeviceLimitationRules = () => {
    const { chosenDevice, limitationRules, setLimitationRules } = this

    const newLimitationRules = adjustChosenDeviceLimitationRules(
      limitationRules,
      chosenDevice,
      'add',
    )

    if (newLimitationRules) {
      setLimitationRules(newLimitationRules)
    }
  }

  toggleAccessorySelection = (accessory: OrderDevice) => {
    const { chosenAccessories, limitationRules, setLimitationRules } = this

    toggleAccessory(
      accessory,
      chosenAccessories,
      this.isProductOffer,
      limitationRules,
      setLimitationRules,
    )
  }

  setAccessoryQuantity = (quantity: number, accessory: OrderDevice) => {
    const { chosenAccessories } = this

    if (chosenAccessories.has(accessory.id)) {
      chosenAccessories.set(accessory.id, { ...accessory, quantity })
    }
  }

  setAccessoryTextField = (
    key: OrderItemTextField,
    value: string,
    accessory: OrderDevice,
  ) => {
    const { chosenAccessories } = this

    if (chosenAccessories.has(accessory.id)) {
      chosenAccessories.set(accessory.id, {
        ...accessory,
        [key]: value,
      })
    }
  }

  setAddonDeviceTextField = (
    key: OrderItemTextField,
    value: string,
    addonDeviceId?: OrderDevice['id'],
  ) => {
    if (this.chosenDevice?.addonDevices && addonDeviceId) {
      const updatedAddonDevices = this.chosenDevice.addonDevices.map(
        addonDevice => {
          if (addonDevice.id === addonDeviceId) {
            return {
              ...addonDevice,
              [key]: value,
            }
          }

          return addonDevice
        },
      )

      this.chosenDevice.addonDevices = updatedAddonDevices
    }
  }

  setCurrentStep = (section: OrderStepEnum | null) => {
    this.currentStep = section
  }

  setLimitationRules = (limitations: OrderLimitations | null) => {
    this.limitationRules = limitations
  }

  setChosenShippingAddress = (
    shippingAddress: ShippingAddressFormSchema | null,
  ) => {
    this.chosenShippingAddress = shippingAddress
  }

  setFilters = (filters: OrderFilters) => {
    this.filters = filters
  }

  clearFilters = () => {
    this.filters = new Map([
      [OrderFilterTypeEnum.Colors, new Set<string>()],
      [OrderFilterTypeEnum.Manufacturers, new Set<string>()],
      [OrderFilterTypeEnum.Storages, new Set<string>()],
      [OrderFilterTypeEnum.ProductTypes, new Set<string>()],
    ])
  }

  setSortCriteria = (sortCriteria: OrderSortCriteriaEnum) => {
    this.sortCriteria = sortCriteria
  }

  setAccessories = (accessories: OrderDevice[] | null) => {
    this.accessories = accessories
  }

  setOfficeAddress = (officeAddress: OrderAddressFields | null) => {
    this.officeAddress = officeAddress
  }

  setFetchError = (isError: boolean) => {
    this.fetchError = isError
  }

  setMutationError = (isError: boolean) => {
    this.mutationError = isError
  }

  setIsLoading = (isLoading: boolean) => {
    this.isLoading = isLoading
  }

  setIsAccessoriesLoading = (isLoading: boolean) => {
    this.isAccessoriesLoading = isLoading
  }

  setIsCYOD = (isCYOD: boolean) => {
    this.isCYOD = isCYOD
  }

  setIsProductOffer = (isProductOffer: boolean) => {
    this.isProductOffer = isProductOffer
  }

  setIsOrderDisabled = (isOrderDisabled: boolean) => {
    this.isOrderDisabled = isOrderDisabled
  }

  setIsUpgradeFromProlongingPage = (isUpgradeFromProlongingPage: boolean) => {
    this.isUpgradeFromProlongingPage = isUpgradeFromProlongingPage
  }

  setOrderedDevices = (
    devices:
      | mutationNewDeviceCaseItem_createNewDeviceCaseItem_subCaseItems[]
      | number
      | null,
  ) => {
    this.orderedDevices = devices
  }

  setOrderEmployeeName = (fullName: string | null) => {
    this.orderEmployeeName = fullName
  }

  setNetPriceCalculator = (
    netPriceCalculatorValue: NetPriceCalculatorInput,
  ) => {
    this.netPriceCalculator = netPriceCalculatorValue
  }

  setOriginRoute = (originRoute: OriginRoute) => {
    this.originRoute = originRoute
  }

  setEmployeeContact = ({
    data = null,
    isLoading = false,
  }: Partial<EmployeeContactData>) => {
    this.employeeContact = { data, isLoading }
  }

  setExternalId = (orderExternalId: string | null) => {
    this.orderExternalId = orderExternalId
  }

  fetchEmployeeContact = async (id: string) => {
    const { setEmployeeContact, setOfficeAddress } = this
    const { apolloClient } = ApolloLoader

    setEmployeeContact({ isLoading: true })

    if (!apolloClient) {
      setEmployeeContact({ isLoading: false })

      return
    }

    try {
      const response = await apolloClient.query<
        getEmployeeContact,
        getEmployeeContactVariables
      >({
        query: GET_EMPLOYEE_CONTACT,
        variables: {
          id,
        },
      })

      const data = response.data.employee.contact

      setEmployeeContact({ data, isLoading: false })

      const employeeOfficeAddress =
        response.data.employee.office?.address ||
        response.data.employee.organisation?.contact?.address

      setOfficeAddress(employeeOfficeAddress || null)
    } catch (error) {
      setEmployeeContact({ isLoading: false })
    }
  }

  setVendingMachineData = ({
    data = null,
    isLoading = false,
  }: Partial<VendingMachinesData>) => {
    this.vendingMachinesData = { data, isLoading }
  }

  fetchVendingMachineData = async (organisationId?: string) => {
    const { chosenDevice, chosenAccessories, setVendingMachineData } = this
    const { apolloClient } = ApolloLoader

    setVendingMachineData({ isLoading: true })

    if (!apolloClient || !chosenDevice || !organisationId) {
      setVendingMachineData({ isLoading: false })

      return
    }

    try {
      const accessoriesIds = [...chosenAccessories].map(
        ([_, accessory]) => accessory.deviceDefinition.id,
      )

      const response = await apolloClient.query<
        getVendingMachineData,
        getVendingMachineDataVariables
      >({
        query: GET_VENDING_MACHINE_DATA,
        variables: {
          input: {
            DeviceDefinitionIDs: [
              chosenDevice?.deviceDefinition.id,
              ...accessoriesIds,
            ],
            orgID: organisationId,
          },
        },
      })

      const data =
        response.data?.checkVendingMachineAvailability?.vendingMachines ?? []

      setVendingMachineData({ data, isLoading: false })
    } catch (error) {
      setVendingMachineData({ isLoading: false })
    }
  }

  clearAllChoices = () => {
    this.clearChosenDeviceWithAccessories()
    this.setOrderedDevices(null)
    this.setChosenShippingAddress(null)
    this.setCurrentStep(OrderStepEnum.device)
    this.clearFilters()
    this.setSortCriteria(OrderSortCriteriaEnum.Default)
    this.setEmployeeContact({ data: null, isLoading: true })
    this.setExternalId(null)
  }

  resetAll = () => {
    this.clearAllChoices()
    this.setChosenDevice(null)
    this.setAccessories(null)
    this.setOfficeAddress(null)
    this.setOrderDevices(null)
    this.setOrderEmployeeName(null)
    this.setFetchError(false)
    this.setIsLoading(false)
    this.setLimitationRules(null)
    this.setMutationError(false)
    this.setNetPriceCalculator(NetPriceCalculatorDefaultValues)
    this.setOriginRoute(null)
    this.setIsOrderDisabled(false)
    this.setIsUpgradeFromProlongingPage(false)
    this.setExternalId(null)
  }

  public get getTotalPrice() {
    const { chosenAccessories, chosenDevice, previewColumnDevice } = this

    return countTotalPrice(chosenAccessories, chosenDevice, previewColumnDevice)
  }

  public get shouldSkipAccessories() {
    const { accessories, limitationRules } = this

    return skipAccessories(accessories, limitationRules, this.isProductOffer)
  }
}
