import { EmployeeStore } from 'Stores/employeeStore/employeeStore'
import { OrderStore } from 'Stores/orderStore/orderStore'
import { OrderAddressFields } from 'Stores/orderStore/orderStore.types'
import { OrganisationStore } from 'Stores/organisationStore/organisationStore'
import { PortalSettingsStore } from 'Stores/portalSettingsStore/portalSettingsStore'
import { UserModeEnum } from 'Stores/portalSettingsStore/portalSettingsStore.types'
import { RentalStore } from 'Stores/rentalStore/rentalStore'
import { UserStore } from 'Stores/userStore/userStore'

import {
  formatOffersShippingAddressDataBeforeSubmit,
  formatShippingAddressDataBeforeSubmit,
} from 'Utilities'

import { mutationCreateOfferOrdersVariables } from 'Constants/graphql/mutations/__generated__/mutationCreateOfferOrders'
import { mutationEarlyUpgradeDeviceVariables } from 'Constants/graphql/mutations/__generated__/mutationEarlyUpgradeDevice'
import { mutationNewDeviceCaseItemVariables } from 'Constants/graphql/mutations/__generated__/mutationNewDeviceCaseItem'
import { mutationUpgradeDeviceVariables } from 'Constants/graphql/mutations/__generated__/mutationUpgradeDevice'

import { OrderAddressType, OrderType } from 'Portal/__generated__/globalTypes'
import { productOffersDeviceItemPayloadMapper } from 'Portal/src/mappers/orderDeviceMapper/orderDevice.mapper'

interface getOrderMutationVariablesParams {
  orderType: OrderType
  chosenAccessories: OrderStore['chosenAccessories']
  chosenDevice: OrderStore['chosenDevice']
  activeEmployee: EmployeeStore['employee'] | null
  activeOrganisation: OrganisationStore['organisation'] | null
  chosenShippingAddress: OrderStore['chosenShippingAddress']
  user: UserStore['user']
  activeEmployeeId?: string
  officeAddress?: OrderAddressFields | null
  rentalDetails?: RentalStore['rentalDetails']
  orderExternalId?: OrderStore['orderExternalId']
}

export interface getRentCategoryOrderMutationVariablesParams
  extends getOrderMutationVariablesParams {
  userMode?: PortalSettingsStore['userMode']
}

export const getRentCategoryOrderMutationVariables = (
  params: getRentCategoryOrderMutationVariablesParams,
):
  | mutationNewDeviceCaseItemVariables
  | mutationEarlyUpgradeDeviceVariables
  | mutationUpgradeDeviceVariables => {
  const {
    orderType,
    chosenAccessories,
    chosenDevice,
    activeEmployee,
    activeOrganisation,
    chosenShippingAddress,
    activeEmployeeId,
    user,
    userMode,
    officeAddress,
    rentalDetails,
    orderExternalId,
  } = params

  const shippingAddressLabel =
    userMode !== UserModeEnum.NEW_JOINER
      ? chosenShippingAddress?._label
      : 'New joiner'

  const isEmployeeAndUsingOfficeAddress =
    !!activeEmployee && chosenShippingAddress?._label === officeAddress?.name

  return {
    input: {
      accessories: chosenAccessories.size
        ? [...chosenAccessories].map(([, accessory]) => ({
            billingIdentifier: accessory?.billingIdentifier,
            customClientReference: accessory?.customClientReference,
            customerLabel: accessory?.customerLabel ?? '',
            id: accessory.deviceDefinition.id,
            poRcn: accessory?.poRcn,
            quantity: accessory.quantity,
          }))
        : null,
      addressID: isEmployeeAndUsingOfficeAddress
        ? officeAddress?.id
        : undefined,
      device: {
        billingIdentifier: chosenDevice?.billingIdentifier,
        customClientReference: chosenDevice?.customClientReference,
        customerLabel: chosenDevice?.customerLabel ?? '',
        id: chosenDevice?.deviceDefinition.id ?? '',
        poRcn: chosenDevice?.poRcn,
        quantity: chosenDevice?.quantity ?? 1,
      },
      employeeID: activeEmployeeId,
      externalID: orderExternalId || undefined,
      isRepairUpgrade:
        orderType === OrderType.newDevice
          ? undefined
          : orderType === OrderType.repairUpgrade,
      organisationID:
        activeEmployee?.organisation?.id.toString() ||
        activeOrganisation?.id.toString() ||
        '',
      previousRentalID:
        orderType === OrderType.newDevice
          ? undefined
          : rentalDetails?.rental.id ?? '',
      previousStocklistItemID:
        orderType === OrderType.newDevice
          ? undefined
          : rentalDetails?.rental.stocklistItem?.id,
      rentCategoryID: chosenDevice?.rentCategoryId || '',
      requestingUserID: user.userId?.toString() || '',
      shippingAddress: {
        ...formatShippingAddressDataBeforeSubmit(
          chosenShippingAddress,
          isEmployeeAndUsingOfficeAddress,
        )!,
        _label:
          orderType === OrderType.newDevice ? shippingAddressLabel : undefined,
      },
      vendingMachineID:
        orderType === OrderType.newDevice
          ? chosenShippingAddress?._vendingMachineId
          : undefined,
    },
  }
}

export interface getOffersOrderMutationVariablesParams
  extends getOrderMutationVariablesParams {}

export const getOfferOrderMutationVariables = (
  params: getOffersOrderMutationVariablesParams,
): mutationCreateOfferOrdersVariables => {
  const {
    orderType,
    chosenAccessories,
    chosenDevice,
    activeEmployee,
    activeOrganisation,
    chosenShippingAddress,
    activeEmployeeId,
    user,
    officeAddress,
    rentalDetails,
    orderExternalId,
  } = params

  const isEmployeeAndUsingOfficeAddress =
    !!activeEmployee && chosenShippingAddress?._label === officeAddress?.name

  return {
    input: {
      orders: [
        {
          addressID: isEmployeeAndUsingOfficeAddress
            ? officeAddress?.id
            : undefined,
          employeeId: activeEmployeeId,
          externalID: orderExternalId || undefined,
          items: productOffersDeviceItemPayloadMapper(
            chosenDevice,
            chosenAccessories.size
              ? [...chosenAccessories]
                  .map(([_id, accessory]) => accessory)
                  .filter(accessory =>
                    orderType === OrderType.newDevice
                      ? accessory.quantity > 0
                      : true,
                  )
              : null,
          ),
          offerId: chosenDevice?.offerId || '',
          orderType,
          organisationId:
            activeEmployee?.organisation?.id.toString() ||
            activeOrganisation?.id.toString() ||
            '',
          previousRentalID:
            orderType === OrderType.newDevice
              ? undefined
              : rentalDetails?.rental.id,
          shippingAddress: formatOffersShippingAddressDataBeforeSubmit(
            chosenShippingAddress,
            OrderAddressType.shipping,
            isEmployeeAndUsingOfficeAddress,
          ),
          vendingMachineID:
            chosenShippingAddress?._vendingMachineId || undefined,
        },
      ],
      requestingUserID: user.userId?.toString() ?? '0',
    },
  }
}
