import { Container } from 'react-bootstrap'
import { observer } from 'mobx-react'

import { ContactDescription } from 'Modules/contact/components/ContactDescription/ContactDescription'
import { ContactDetails } from 'Modules/contact/components/ContactDetails/ContactDetails'

import { PageHeader } from 'Components/PageHeader/PageHeader'

import { store } from 'Stores/index'

import { PortalSectionEnum } from 'Constants/portalSection'

export const Contact = observer(() => {
  const { portalSettingsStore } = store
  const { setCurrentPortalSection } = portalSettingsStore

  setCurrentPortalSection(PortalSectionEnum.Contact)

  return (
    <Container className="pt-6 Contact" fluid>
      <div role="banner">
        <PageHeader portalSection={PortalSectionEnum.Contact} />
      </div>

      <div className="row">
        <div className="col-12 col-xl-8 mb-4 mb-xl-0">
          <ContactDescription />
        </div>

        <div className="col-12 col-xl-4 mb-4 mb-xl-0">
          <ContactDetails />
        </div>

        {/* <div className="d-block d-lg-none col-12 mb-9">
          <Card>
            <CardBody>
              <EmployeeTile />
            </CardBody>
          </Card>
        </div> */}
      </div>
    </Container>
  )
})
