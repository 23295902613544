import { FormattedMessage } from 'react-intl'

import { Card, CardBody, PrimaryButton } from 'Components/_theme'
import { DecorHeader } from 'Components/DecorHeader/DecorHeader'

import { openIntercomChat } from 'Utilities'

export const ContactDescription = () => (
  <Card>
    <DecorHeader title="ContactDescription_header" />

    <CardBody className="Contact-MainCardBody">
      <p className="m-0">
        <FormattedMessage id="ContactDescription_description" />
      </p>

      <PrimaryButton
        className="ContactLandingDescription__button mb-lg-9"
        onClick={openIntercomChat}
      >
        <FormattedMessage id="Contact_chat_with_us" />
      </PrimaryButton>

      {/* <div className="d-none d-lg-block">
        <hr className="mb-9" />

        <EmployeeTile />
      </div> */}
    </CardBody>
  </Card>
)
