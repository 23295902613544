import { gql } from '@apollo/client'

export const GET_RENTAL = gql`
  query getRental(
    $rentalID: ID!
    $isOrganisationView: Boolean!
    $organisationId: ID
    $employeeId: ID
  ) {
    rental(
      id: $rentalID
      organisationId: $organisationId
      employeeId: $employeeId
    ) {
      id
      deviceID
      forcePriceHide
      actions(isOrganisationView: $isOrganisationView) {
        showExchangeAction
        showEarlyUpgradeAction
        showStandardUpgradeAction
        showLostOrStolenAction
        showReplaceBrokenDeviceAction
        showUpgradeAction
        showCancelAction
        showReturnAction
        showReassignDeviceAction
        showDeviceProlongingAction
        showPurchaseDeviceAction
        showRepairUpgradeAction
        showReplaceFromDepotAction
        showReplacementOrderFlow
      }
      alerts(isOrganisationView: $isOrganisationView) {
        showUpgradePossibleAlert
        showEarlyUpgradePossibleAlert
        showProlongingPossibleAlert
        showOpenLostDeviceAlert
        showOpenRepairDeviceAlert
        showOpenCancelDeviceAlert
      }
      minimumContractDuration
      minimumContractDurationDate
      startAt
      endAt
      earlyUpgradeDate
      earliestContractTermination
      isEarlyUpgradeEligible
      amount
      coPaymentAmount
      isBARBContract
      isUpgradeEligible
      isUpgradePending
      isProlongingEligible
      isReplacementDeviceAvailableInDepot
      isLostStolenNoReplacementEnabled
      newDeviceDate
      upgradeAmount
      poRcn
      customClientReference
      billingIdentifier
      rentalProlonging {
        validFrom
        validTill
      }
      employee {
        id
        office {
          locationName
          address {
            city
            country
            id
            name
            nameAddition
            street
            zipcode
            streetAddition
          }
          organisation {
            shortName
          }
        }
        contact {
          id
          firstName
          email
          lastName
          phoneNumber
          type
          address {
            city
            country
            id
            name
            nameAddition
            street
            zipcode
            streetAddition
          }
        }
      }
      organisation {
        id
        fullName
        shortName
        individualTermsEN
        individualTermsDE
        contact {
          address {
            city
            country
            id
            name
            nameAddition
            street
            zipcode
            streetAddition
          }
        }
      }
      phonePlan {
        endAt
        id
        simcardNumber
        startAt
        phoneNumber
        phonePlanTariff {
          name
          logo
        }
      }
      stocklistItem {
        id
        imei
        imei2
        depot {
          locationName
        }
        eid
        serialNumber
        deviceDefinition {
          id
          name
          codename
          storage
          color
          imageURL
          manufacturer
          productType
        }
      }
      stocklistItemHistory {
        totalCount
        stocklistItems {
          id
          imei
          serialNumber
          deviceDefinition {
            name
            imageURL
          }
        }
      }
      costCenter {
        name
      }
      rentCategory {
        id
        copaymentNet
        copaymentGross
        descriptionDe
        descriptionEn
        deviceDefinitions {
          deviceDefinitions {
            id
            codename
            color
            imageURL
            manufacturer
            storage
          }
        }
        displaySortOrder
        name
      }
      rentalProlongingPrice {
        basisAmount
        copaymentAmount
        duration
        startDate
        endDate
        prolongAfterInMonths
      }
      caseItems(orderBy: { field: id, order: DESC }) {
        caseItems {
          id
          status
          caseType
          assignedEmployee {
            id
            contact {
              firstName
              lastName
            }
          }
          created
          parentCaseItem {
            id
            status
            caseType
            assignedEmployee {
              id
              contact {
                firstName
                lastName
              }
            }
            created
          }
        }
      }
    }
  }
`

export const GET_RENTALS_PREVIEW_COLUMN = gql`
  query getRentalsPreviewColumn($id: ID!) {
    rental(id: $id) {
      amount
      id
      isEarlyUpgradeEligible
      isUpgradeEligible
      isUpgradePending
      minimumContractDuration
      startAt
      coPaymentAmount
      newDeviceDate
      forcePriceHide
      rentalProlonging {
        validFrom
      }
      stocklistItem {
        imei
        imei2
        eid
        serialNumber
        deviceDefinition {
          name
          imageURL
          productType
        }
      }
    }
  }
`
