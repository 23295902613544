import { backendEnTranslations } from './backend/backend.en'
import { dataPrivacyTranslations } from './dataPrivacy/dataPrivacy.en'

const en = {
  Accept: 'Accept',
  AccessoriesChooser_limitation_info:
    'You can select only {amount} {amount, plural, =0 {accessories} =1 {accessory} other {accessories}}. Please unselect one if you wish to choose another.',
  AccessoriesChooser_limitation_info_one:
    'You can select only one accessory. Please unselect one if you wish to choose another.',
  AccessoriesChooser_skip: 'Skip',
  AccessoriesChooser_total_products: 'We found {total} products',
  Action: 'Action',
  Active_employees: 'Active employees',
  Active_phone_plans: 'Active phone plans',
  Active_rentals: 'Active rentals',
  Address: 'Address',
  Addresses: 'Addresses',
  Admin: 'Admin',
  Admin_dashboard_early_upgrade_text:
    'Rental eligible for upgrade/ early upgrade',
  Agent: 'Agent',
  Alert_disabled_order_process:
    'Currently, you have not added any devices for selection. To update your portfolio, please contact us by e-mail at ',
  Amount: 'Amount',
  Assign: 'Assign',
  Assign_device: 'Assign device',
  Assign_device_to_employee: 'Assign device to employee',
  Attachment_A: 'Attachment A',
  Attn: 'Attn',
  Available: 'available',
  Awaiting_processing: 'Awaiting processing',
  Back: 'Back',
  Back_to_login: 'Back to login',
  BenefitSlide_get_in_touch_content:
    'Do you need assistance or do you have questions? Do not hesitate to contact us. We will be glad to help you!',
  BenefitSlide_get_in_touch_header: 'Get in Touch',
  BenefitSlide_manage_devices_content:
    'See an overview of your devices and phone plans in our portal - including device IDs, rental periods and other basic information about your gadgets.',
  BenefitSlide_manage_devices_header: 'Manage Devices',
  BenefitSlide_self_service_content:
    'Is something wrong with your phone? Here you can contact the Everphone team directly. No need to bother your IT team or admin!',
  BenefitSlide_self_service_header: 'Self Service',
  BenefitSlide_upgrade_devices_content:
    'Is your device ready for a free upgrade? Check out your upgrade options to see if you can order the latest iOS or Android phone model.',
  BenefitSlide_upgrade_devices_header: 'Upgrade Devices',
  Broken_device: 'Broken device',
  Business_line: 'Business line',
  Buy_leaseback: 'Buy and leaseback',
  Cancel: 'Cancel',
  Cancel_device: 'Cancel device',
  Cancel_phone_plan: 'Cancel phone plan',
  Cancelled: 'Cancelled',
  CardActions_noActionEmployee_description:
    'At the moment you can not perform any changes to this employee',
  CardActions_noActionRental_description:
    'At the moment you can not perform any changes to your rental',
  CardActions_noActionReturn_description:
    'At the moment you can not perform any changes to this return',
  CardActions_noAction_title: 'No action available',
  Carrier: 'Delivery carrier',
  ChangeExitDate: 'Change exit date',
  ChangeOfDeliveryAddress: 'Change of delivery address',
  Change_phone_plan: 'Change phone plan',
  Choose_new_device: 'Choose new device',
  Choose_phone_plan: 'Choose phone plan',
  City: 'City',
  CityState: 'City, State',
  Clear: 'Clear',
  Clear_all: 'Clear all',
  Clear_filter: 'Clear filter',
  Close: 'Close',
  Color: 'Color',
  Comment: 'Comment',
  Completed: 'Completed',
  Confirm: 'Confirm',
  Confirmation_date: 'Confirmation date',
  ContactDescription_description:
    'The easiest way to get in touch with us is through the chat. Simply click on the button to open a chat window. If your organization does not support help via chat, please use the email and phone contact options.',
  ContactDescription_header: 'Have a question?',
  ContactDetails_description: 'Any questions or remarks? Contact us!',
  ContactDetails_header: 'All contact options',
  ContactDetails_openingHours: 'Monday–Sunday, 7 am–8 pm',
  ContactDetails_service: '(Service in English and German)',
  ContactLandingDescription_description:
    'The easiest way to get in touch with us is through the chat. Simply click on the button to open a chat window.',
  Contact_button: 'Back to dashboard',
  Contact_chat_with_us: 'Open chat',
  Contact_login: 'Log in',
  Contact_login_text: 'Already have access to the Portal?',
  Contact_position: 'Head of Client Services',
  Contact_position_text:
    'My team and I are here for you. Get mobile support in just a couple of clicks!',
  Contact_us: 'Contact us',
  Continue: 'Continue',
  CookieApproval_cookies_stored_on_computer:
    'This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you.',
  CookieApproval_heading: 'This website uses cookies',
  CookieApproval_if_you_decline:
    'If you decline, your information won’t be tracked when you visit this website.',
  CookieApproval_improve_experience_info:
    'We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media.',
  Copy: 'Copy',
  CostCenterSelect_Create: 'Create cost center',
  CostCenterSelect_NoResults:
    'Please enter at least one character in order to create cost center.',
  Cost_center: 'Cost center',
  Costcenter: 'Cost center',
  Country: 'Country',
  Create_DHL_return_label: 'Create new DHL return label',
  Create_employee: 'Create employee',
  Created_by: 'Created by',
  Current: 'Current',
  Custom_address: 'Custom address',
  DHL_Return_Label: 'DHL return label',
  Dashboard: 'Dashboard',
  DashboardFleetOverviewCard_header: 'Fleet overview',
  DashboardFleetOverviewCard_subtitle: 'All devices',
  DashboardOrdersReportCard_orders_report_header: 'Orders report',
  DashboardOrdersReportCard_orders_report_subtitle: 'View orders by order type',
  DashboardStatisticCard_contact_us: 'Contact us to get started',
  DashboardStatisticCard_this_month: '{percent}% this month',
  Dashboard_everphone_contact: 'Your Everphone contact',
  Dashboard_most_popular: 'Most popular devices',
  Dashboard_the_benefits: 'The benefits',
  Data_privacy: 'Data Privacy',
  Date: 'Date',
  Delivery_status_Delivered: 'Your device has been delivered',
  Delivery_status_Delivery: 'Awaiting delivery',
  Delivery_status_Failure:
    'Delivery failed - your device is being returned to Everphone',
  Delivery_status_Transit: 'Your device has been shipped',
  Delivery_status_return_Delivered: 'Returned successfully to Everphone',
  Delivery_status_return_Failure:
    'Delivery failed - device is being returned to sender',
  Delivery_status_return_Transit: 'Your return is in transit',
  Depot: 'Depot',
  DepotListContent_badge_return: 'Return',
  DepotListContent_header_devices: 'Devices',
  DepotListContent_header_name: 'Name',
  DepotListContent_header_type: 'Type',
  DepotStockModal_no_available: 'No "{name}" available',
  DepotStockModal_serial_number: 'Serial No.',
  DepotTable_in_depot: 'in depot',
  Depot_details: 'Depot details',
  Depots: 'Depots',
  Device: 'Device',
  DeviceChooserFilters_color: 'Color',
  DeviceChooserFilters_device_type: 'Device type',
  DeviceChooserFilters_filter: 'Filter',
  DeviceChooserFilters_manufacturer: 'Manufacturer',
  DeviceChooserFilters_memory: 'Memory',
  DeviceChooserFilters_price: 'Price',
  DeviceChooserFilters_recommended: 'Recommended',
  DeviceChooserFilters_total_products: 'We found {total} products',
  DeviceChooserFilters_view_all: 'View all filters',
  DeviceChooserFilters_view_less: 'View less filters',
  DeviceChooser_limit_exceeded:
    'Sorry, you cannot order more devices. Please contact your administrator for more information.',
  DeviceDetails_exchange_requested: 'Exchange requested',
  DeviceDetails_replacement_requested: 'Replacement requested',
  DeviceListContent_header__employee: 'Employee',
  DeviceListContent_header__name: 'Name',
  DeviceListContent_header__start_date: 'Start date',
  DeviceListContent_header__status: 'Status',
  DeviceSlide_purchase_id: 'Purchase ID',
  DeviceSlide_request_date: 'Request date',
  DeviceSlide_request_id: 'Request ID',
  DeviceSlide_start_date: 'Start date',
  DeviceSlide_view_rental: 'View rental',
  DeviceSlide_view_request: 'View request',
  Device_history: 'Device history',
  Device_locked: 'Device locked',
  Device_locked_alert:
    'This device is currently either locked or still has MDM restrictions. Please correct this in order to continue with the return.',
  Device_locked_badge_in_customer_lock: 'Blocked - User Lock',
  Device_locked_badge_in_mdm: 'Blocked - MDM Lock',
  Device_locked_badge_in_mdm_and_customer_lock: 'Blocked - User & MDM Lock',
  Device_lost_stolen: 'Device lost / stolen',
  Devices: 'Devices',
  DevicesCard_devices_header: 'My devices and orders',
  DevicesCard_purchases_header: 'My purchases',
  DevicesCard_total:
    '(Total: {total} {total, plural, =0 {devices} one {device} other {devices}})',
  Done: 'Done',
  Download: 'Download',
  Download_report_error: 'Sorry! Something went wrong.',
  Download_report_preparing: 'Preparing',
  Download_report_preparing_message:
    'We’re on it! Your report is currently being prepared. A link for downloading the report will appear here as soon as it’s available.',
  Download_report_report_ready: 'Your report from {time} is ready to download.',
  Download_report_report_ready_download: 'Click here to download',
  Download_report_report_ready_order_message:
    'Orders older than 1 year are not included in the report.',
  Download_report_request_report: 'Request report',
  Duration: 'Duration',
  Early_Upgrade: 'Early upgrade',
  Early_upgrade_device: 'Early upgrade device',
  Edit: 'Edit',
  Edit_address: 'Edit address',
  Edit_employee: 'Edit employee',
  Email: 'Email',
  Employee: 'Employee',
  EmployeeDashboardServiceApp_rent_button: 'Rent device',
  EmployeeDashboardServiceApp_title: 'My devices',
  EmployeeDashboard_button: 'Discover offers now',
  EmployeeDashboard_title: 'Your company devices',
  EmployeeDashboard_title_content:
    'In this portal you can manage your company devices: organize existing devices, order exchanges if your device is broken or upgrade your gadgets! You can also use this portal for FAQ and to get in contact with our friendly customer service using the chat.',
  EmployeeExitCard_heading: 'Upcoming employee exit dates',
  EmployeeListContent_IMEI: 'IMEI',
  EmployeeListContent_Rentals: 'Rentals',
  EmployeeListContent_header_device: 'Device',
  EmployeeListContent_header_employee: 'Employee',
  EmployeeListContent_number_of_devices: 'Devices',
  EmployeeOffboarding_date_in_past: 'Exit date must be today or in the future.',
  EmployeeOffboarding_error: 'Something went wrong. Please try again.',
  EmployeePurchases_no_purchases_yet:
    "You don't have any purchases yet. Would you like to begin your first purchase with Everphone?",
  Employee_details: 'Employee details',
  Employee_id: 'Employee ID',
  Employee_leaves: 'Employee leaves firm',
  Employees: 'Employees',
  EmployeesSelect_NoResults: 'No employees found',
  End: 'End',
  English: 'English',
  ErrorSubmittingRequest:
    'Unfortunately there was an error submitting your request.',
  Error_heading: 'Oops!',
  Error_occurred: 'An error has occurred.',
  Error_refresh_page: 'Please refresh this page or navigate to another one.',
  EverphonePlusBenefits_buying_offer_content:
    'After the end of rental period, you can choose to extend it, return the device back to us or to buy the device at an attractive price.',
  EverphonePlusBenefits_buying_offer_header:
    'Option to buy at the end of rental period',
  EverphonePlusBenefits_damage_swap_content:
    'With Everphone Plus you have the device fully covered and it can be exchanged at no extra cost when the it is defective or accidentally damaged.',
  EverphonePlusBenefits_damage_swap_header:
    'Receive a replacement device in case of damage',
  EverphonePlusBenefits_enormous_savings_content:
    'Save up to 52% when you rent a device through your employer instead of buying',
  EverphonePlusBenefits_enormous_savings_header: 'Big savings',
  EverphonePlusBenefits_family_friends_content:
    'You can rent your favorite device from Everphone – enabled and unlocked for your private use, so you can completely configure them the way you prefer.',
  EverphonePlusBenefits_family_friends_header: 'Ideal for you',
  EverphonePlusDashboard_see_offers: 'See offers',
  EverphonePlusDashboard_title: 'Rent and save with Everphone Plus',
  EverphonePlusDashboard_title_content:
    'You can now rent and use mobile devices and accessories for private use through your employer. The rental amount is deducted directly from your monthly salary. Instead of paying the high upfront cost for your favourite device, pay a monthly rental fee and benefit from the pre-tax deductions.',
  Exchange: 'Exchange',
  ExchangeDeviceModal_shipping_label_info:
    'The package comes with a shipping label. Please return the old device within the next 14 days.',
  Exchange_device: 'Exchange device',
  Excl_tax: 'Excl. VAT',
  Exit_date: 'Exit date',
  Expand_content: 'Expand content',
  Extend: 'Extend',
  Extend_now: 'Extend now',
  Extend_rental: 'Extend rental or choose a new device',
  Fallback: 'MISSING_LABEL',
  FaqCard_description_part1:
    'Find answers to frequently asked questions in our help center.',
  FaqCard_description_part2:
    'Alternatively, we’re happy to answer other questions via chat.',
  FaqCard_primary_cta: 'Browse FAQ',
  FaqCard_secondary_cta: 'Open chat',
  FaqCard_title: 'FAQ',
  Field_invalid: 'This field is invalid',
  Filter: 'Filter',
  Filter_by: 'Filter by',
  Filter_no_results_content:
    'Please edit your filter or clear all of your selection',
  First_and_last_name: 'First and last name...',
  First_name: 'First name',
  For_employee: 'For employee (optional)',
  ForgotPasswordButton_label: 'Did you forget your password?',
  FormMessage_email: 'This field must contain a valid email address.',
  FormMessage_maxLength:
    'This field must contain at most {amount} character(s).',
  FormMessage_minLength:
    'This field must contain at least {amount} character(s).',
  FormMessage_required: 'This field is required.',
  German: 'German',
  GlobalSearch_CloseIcon: 'Close search',
  GlobalSearch_Heading: 'Your Search Suggestions',
  GlobalSearch_InCategory: 'in {category}',
  GlobalSearch_InputPlaceholder:
    'Search for a device, employee or phone plan...',
  GlobalSearch_InputValidationMessage:
    'We didn’t find a match, please enter more than {limit} characters.',
  GlobalSearch_NoResultsMessage:
    'Sorry, we didn’t find any results for your search.',
  GlobalSearch_OpenIcon: 'Open search',
  GlobalSearch_RelatedItems: 'Related items',
  GlobalSearch_SearchAll: 'Search all',
  GlobalSearch_SearchResults_header: 'Search results',
  GlobalSearch_SearchResults_show_total:
    'Show {amount} related {amount, plural, =0 {items} one {item} other {items}}',
  GlobalSearch_SearchResults_total:
    'We found {amount} related {amount, plural, =0 {items} one {item} other {items}}',
  GoToPage_after: 'page',
  GoToPage_pageInput: 'Page input',
  ILostMyDevice: 'I lost my device',
  IMEI: 'IMEI',
  IMEI2: 'IMEI2',
  Id: 'Id',
  Img_alt_contact_guy:
    "Icon of a customer support representative wearing a headset, sitting at a desk with a laptop displaying the word 'ever.'",
  Img_alt_dashboard_banner:
    'A man standing and a woman sitting at a desk, discussing work in a bright, modern office with large windows.',
  Img_alt_faq:
    'Icon of a magnifying glass with a question mark over two overlapping web browser windows.',
  Img_alt_impact_tracker:
    'Icon depicting a factory on a globe with CO₂ emissions, a leaf, and a water droplet symbolizing environmental impact.',
  Img_alt_list_message: 'Messages Icon',
  Img_alt_login_devices:
    'Four electronic devices—a laptop, a tablet, and two smartphones—displaying colorful abstract designs on their screens.',
  Img_alt_no_action:
    "Icon of a computer monitor with an overlapping web browser window and an 'X' symbol, indicating an error or blocked action.",
  Img_alt_order_confirmation:
    'Illustration of a smiling delivery person holding a package with a checkmark symbol, indicating that the order is confirmed.',
  Img_alt_org_logo: 'Organisation logo',
  Img_alt_page_error:
    'Icon indicating something unexpected occurred, with a computer monitor, a web browser window, and a refresh symbol suggesting to refresh the page.',
  ImpactTracker_desc: 'Your environmental impact reduction to date',
  ImpactTracker_popover_desc:
    'The ecological footprint of this refurbished device is compared with the footprint of a new device.',
  ImpactTracker_popover_link: 'Find out more',
  ImpactTracker_prolonging_device: 'Prolonged device',
  ImpactTracker_refurbished_device: 'Refurbished device',
  ImpactTracker_saved_co2: 'Saved CO₂',
  ImpactTracker_saved_raw: 'Saved raw materials',
  ImpactTracker_saved_water: 'Saved water',
  ImpactTracker_title: 'Impact tracker',
  Imprint: 'Imprint',
  In_customs: 'In customs',
  In_progress: 'In progress',
  Inactive: 'inactive',
  Incl_tax: 'Incl. VAT',
  InvalidCredentials: 'Incorrect username or password',
  Invoice: 'Invoice',
  Invoices: 'Invoices',
  InvoicesContent_header_amount: 'Amount',
  InvoicesContent_header_attachments: 'Attachments',
  InvoicesContent_header_date: 'Date',
  InvoicesContent_header_due_date: 'Due Date',
  InvoicesContent_header_number: 'Number',
  Item: 'Item',
  Language: 'Language',
  Last_fetch_days:
    'Last updated: {days} {days, plural, =0 {days} one {day} other {days}} ago',
  Last_fetch_hours:
    'Last updated: {hours} {hours, plural, =0 {hours} one {hour} other {hours}} ago',
  Last_fetch_minutes:
    'Last updated: {minutes} {minutes, plural, =0 {minutes} one {minute} other {minutes}} ago',
  Last_fetch_seconds:
    'Last updated: {seconds} {seconds, plural, =0 {seconds} one {second} other {seconds}} ago',
  Last_name: 'Last name',
  Link_to_external_page_title: '{name} - opens in new tab',
  Link_to_page_title: 'Go to {name} page',
  Loading: 'Loading...',
  Login: 'Login',
  Login_SSO: 'Login with SSO',
  Login_error_not_allowed_to_login: 'You are not allowed to login',
  Login_error_password_reset:
    'Your password was changed more than 12 months ago. Due to our security policies, we require every user to change their password at least once per year. Please update your password using the password reset link which is sent to your email address',
  Login_failed: 'Login failed',
  Login_not_authorized: 'You are not authorized to log in',
  Login_page: 'Login page',
  Logout: 'Logout',
  LostDeviceModal_order_new_device: 'Order new device',
  LostOrStolenModal_confirmationNoReplacement_heading:
    'We have received your request',
  LostOrStolenModal_followInternalProcesses:
    'Please make sure you follow your internal processes for lost and stolen devices.',
  LostOrStolenModal_header: 'Report lost/stolen device',
  LostOrStolenModal_noReplacement: 'I do not want a replacement',
  LostOrStolenModal_noReplacementFound:
    'No matching device found as replacement in your depot(s)',
  LostOrStolenModal_orderDepot: 'Order replacement from depot',
  LostOrStolenModal_orderEverphone: 'Order replacement from Everphone',
  LostOrStolenModal_replacementModelNote:
    '* same or similar model will be sent as replacement',
  LostReplacement_info_header: 'Lost/Stolen device',
  LostReplacement_info_text:
    'Please choose a new device of your choice as replacement. A new rental will be created with the selected device',
  Lost_stolen_device: 'Lost / stolen device',
  Message: 'Message',
  Message_for_everphone: 'Message for Everphone',
  Most_recent: 'Most recent',
  MultidatePicker_last_days: 'Last {number} days',
  MultidatePicker_last_months: 'Last {number} months',
  MultidatePicker_next_days: 'Next {number} days',
  MultidatePicker_next_months: 'Next {number} months',
  MultidatePicker_placeholder: 'Choose date',
  MultidatePicker_today: 'Today',
  MultidatePicker_until_today: 'Until today',
  MultidatePicker_yesterday: 'Yesterday',
  My_account: 'My account',
  My_devices: 'My devices',
  Name: 'Name',
  NetPriceCalculator_additional_health_insurance:
    'Additional health insurance contribution rate',
  NetPriceCalculator_apply: 'Apply',
  NetPriceCalculator_calculate: 'Calculate effective prices',
  NetPriceCalculator_child_allowance: 'Child allowance',
  NetPriceCalculator_church_tax: 'Church tax',
  NetPriceCalculator_effective_net_price_calculator:
    'Effective net price calculator',
  NetPriceCalculator_enter_salary: 'Enter your yearly salary',
  NetPriceCalculator_enter_your_health_insurance:
    'Enter your additional health insurance contribution',
  NetPriceCalculator_enter_your_tax_info:
    'Enter your tax information to calculate the estimated effective prices after your gross/net salary conversion.',
  NetPriceCalculator_estimation_disclaimer:
    '** Estimation for orientation purposes only. Your real net salary deduction is determined by your actual tax return.',
  NetPriceCalculator_gross_salary: 'Gross annual salary',
  NetPriceCalculator_prices_visible:
    'Your estimated effective prices are now visible.',
  NetPriceCalculator_recalculate: 'Recalculate Effective Prices',
  NetPriceCalculator_required_fields: 'Required',
  NetPriceCalculator_state: 'State',
  NetPriceCalculator_tax_class: 'Tax class',
  NetPriceCalculator_value: 'Value',
  New: 'New',
  NewOrderConfirmation_color: 'Color: {color}',
  NewOrderConfirmation_heading: 'Your order is confirmed',
  NewOrderConfirmation_items: 'Items',
  NewOrderConfirmation_label: 'Customer label:',
  NewOrderConfirmation_months: '{total} months',
  NewOrderConfirmation_order_date: 'Order date',
  NewOrderConfirmation_price: 'Price',
  NewOrderConfirmation_quantity: 'Quantity: {count}',
  NewOrderConfirmation_rental_period: 'Rental period',
  NewOrderConfirmation_request_id: 'Request ID',
  NewOrderConfirmation_shipping_address: 'Shipping address',
  NewOrderConfirmation_subheading:
    'We have received your order and are getting it ready to be shipped. We will notify you via request message and email when it’s on its way!',
  NewOrderConfirmation_to_dashboard: 'Back to dashboard',
  NewOrderConfirmation_to_employee: 'Back to employee',
  NewOrderConfirmation_to_rental: 'Back to rental',
  NewOrderConfirmation_to_rentals: 'Back to rentals',
  NewOrderConfirmation_total_amount: 'Monthly copayment (excl. VAT)',
  NewOrderConfirmation_total_amount_basis: 'Total monthly amount',
  NewOrderConfirmation_total_amount_gross: 'Monthly copayment (incl. VAT)',
  NewOrderShipping_street_name_placeholder: 'Enter street name',
  NewOrderSummary_accept_terms:
    'I accept all terms and conditions by clicking the order button.',
  NewOrderSummary_billing_identifier_placeholder:
    'Please enter billing identifier (Optional, max {limit} {limit, plural, =0 {character} one {character} other {characters}})',
  NewOrderSummary_custom_client_reference_placeholder:
    'Please enter custom client reference (Optional, max {limit} {limit, plural, =0 {character} one {character} other {characters}})',
  NewOrderSummary_device: 'Device',
  NewOrderSummary_error_header: 'Sorry! Something went wrong',
  NewOrderSummary_error_header_address_error:
    'Sorry! We were unable to complete your order.',
  NewOrderSummary_error_subheader:
    'We were unable to process your order. Please try again.',
  NewOrderSummary_error_subheader_address_error:
    'There is an error in the shipping address you entered. Please correct them and try again',
  NewOrderSummary_external_id: 'External ID',
  NewOrderSummary_external_id_error: 'The external ID must be unique.',
  NewOrderSummary_external_id_placeholder:
    'Please enter your external ID (Optional, unique, max 60 characters)',
  NewOrderSummary_label_placeholder:
    'Please enter customer label details (Optional, max {limit} {limit, plural, =0 {character} one {character} other {characters}})',
  NewOrderSummary_months: 'months',
  NewOrderSummary_net_total:
    'Your roughly calculated total net amount per month (excl. VAT)**  ',
  NewOrderSummary_order: 'Order',
  NewOrderSummary_po_rcn_placeholder:
    'Please enter PO/RCN (Optional, max {limit} {limit, plural, =0 {character} one {character} other {characters}})',
  NewOrderSummary_price: 'Price',
  NewOrderSummary_quantity: 'Quantity',
  NewOrderSummary_rental_duration: 'Rental duration',
  NewOrderSummary_shipping_address: 'Shipping address',
  NewOrderSummary_to_dashboard: 'Back to dashboard',
  NewOrderSummary_total: 'Monthly copayment (excl. VAT)',
  NewOrderSummary_total_basis: 'Total monthly amount',
  NewOrderSummary_total_gross: 'Monthly copayment (incl. VAT)',
  NewOrder_choose_accessories_header: 'Choose your accessories (optional)',
  NewOrder_choose_accessories_subheader:
    'The monthly rental fees and any monthly co-payments are shown without applicable VAT.',
  NewOrder_choose_device_header: 'Choose new device',
  NewOrder_choose_device_subheader:
    'The monthly rental fees and any monthly co-payments are shown without applicable VAT.',
  NewOrder_order_summary_header: 'Order summary',
  NewOrder_order_summary_subheader: 'Please review your order.',
  NewOrder_shipping_address_header: 'Shipping address',
  NewOrder_shipping_address_subheader:
    'What is the shipping address? Where would you like to receive your devices?',
  NewOrder_step: 'Step',
  NewPhonePlanModal_prices_may_vary:
    'Prices may vary and serve only as indicators',
  NewSmartphoneExchangeModal_DescriptionExchange:
    'With the expiration of the rental term, you are now entitled to choose a new device.',
  NewSmartphoneFlow_limit_exceeded:
    'Sorry, you cannot order more devices. Please go to “Requests” to see your recent orders or contact your administrator for more information.',
  New_device: 'New device',
  New_device_bundle: 'New device bundle',
  New_employee: 'New employee',
  New_joiners_this_month: 'New joiners this month',
  New_phone_plan: 'New phone plan',
  New_question: 'New question',
  Next: 'Next',
  No: 'No',
  No_depot_found: 'No depot found',
  No_devices_found: 'No devices found',
  No_name_given: 'No name given',
  No_results: 'You do not have any {entity} yet',
  No_results_title: 'Oops! Cannot find your results',
  OfferAcceptedContent_content:
    'Payment instructions will be sent to you shortly via e-mail, so please keep an eye on your inbox. If you do not receive an e-mail, please contact us for further assistance.',
  OfferAcceptedContent_heading: 'Confirmation received',
  OfferDeclinedContent_content_line1:
    'Please follow the instructions in the e-mail you received to return your device on time.',
  OfferDeclinedContent_content_line2:
    'For any further enquiries please contact our support team.',
  OfferDeclinedContent_heading:
    'Thank you for confirming, your offer will now be expired.',
  OfferErrorContent_content_line1:
    'Seems like something went wrong here. Please contact us via e-mail or phone.',
  OfferErrorContent_heading: 'Oops! Looks like something went wrong',
  OfferPaymentForm_billing_details: 'Billing details',
  OfferPaymentForm_payment_details: 'Payment details',
  OfferPaymentForm_submit: 'Purchase',
  OfferStatus_fallback_content:
    'Seems like something went wrong here. Please contact us via e-mail or phone.',
  OfferStatus_fallback_heading: 'Oops!',
  OfferStatus_payment_failed_content:
    'Unfortunately there was an error processing your purchase. If you need assistance, please contact us.',
  OfferStatus_payment_failed_heading: 'Payment unsuccessful',
  OfferStatus_purchased_content:
    'Thank you for your purchase. We hope you enjoy your device.',
  OfferStatus_purchased_heading: 'Purchase complete!',
  OfferSummaryFooter_conditions_link: 'Conditions',
  OfferSummaryFooter_dataprotection_link: 'Data protection',
  OfferSummary_heading: 'Payment to Everphone Enterprise V GmbH',
  OfferSummary_subtotal: 'Subtotal',
  OfferSummary_tax: 'VAT',
  OfferSummary_total: 'Total amount',
  Office: 'Office',
  Open: 'Open',
  Open_accessibility_menu: 'Open accessibility menu',
  Open_menu: 'Open menu',
  Open_orders: 'Open orders',
  Open_repairs: 'Open repairs',
  Order: 'Order',
  OrderIntercomButton_Contact_form: 'contact page.',
  OrderIntercomButton_Have_a_question: 'Do you have a question?',
  OrderIntercomButton_Open_chat: 'Open Chat',
  OrderIntercomButton_Subtitle:
    'If your organisation does not support the chat function, please use the',
  OrderListContent_header__title: 'Order',
  OrderProlong_extend_rental_header: 'Extend your rental',
  OrderProlong_extend_rental_subheader:
    'Rental extensions are valid for 12 months.',
  OrderSelectionCard_refurbished_desc:
    'See how choosing a refurbished device will help reducing the impact on environment compared to a brand-new device',
  OrderSelectionCard_refurbished_title:
    'Choose a refurbished model as your next device',
  Order_DeviceRentalStep_Subheading:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Massa aliquam ac hac tincidunt leo posuere facilisis. ',
  Order_DeviceRentalStep_heading: 'Upgrade your device',
  Order_EarlyUpgrade_Info:
    'For an upgrade during the rental period, a processing fee will be applied. Upgrades are limited to models within the current or a higher rental category. The rental period for the selected new model will be specified at the time of selection.',
  Order_Upgrade_Info:
    'With the expiration of the rental term, you are now entitled to choose a new device.',
  Order_details: 'Order details',
  Order_new_device: 'Order new device',
  Order_phone_plan: 'Order phone plan',
  Order_replacement_requested: 'Replacement requested',
  Order_upgrade_fee_info:
    'This amount will be deducted from your salary as well as the additional payment.',
  Orders: 'Orders',
  Orders_and_returns: 'Orders & returns',
  Organisation: 'Organisation',
  Organisations: 'Organisations',
  'Original device': 'Original device',
  Other: 'Other',
  OurSupportWillGetInTouch: 'Our support team will get in touch with you soon.',
  Overview: 'Overview',
  PageSizeSelect_label: '{size} rows / page',
  Pagination_jumpBackwardLabel: 'Jump backward',
  Pagination_jumpForwardLabel: 'Jump forward',
  Pagination_nextLabel: 'Next page',
  Pagination_pageLabel: 'Page {page}',
  Pagination_previousLabel: 'Previous page',
  Password: 'Password',
  Password_reset_content:
    'Please check your company email inbox. You will receive the password reset instructions if your account is linked with the company email address you entered.',
  Password_reset_header: 'Password has been reset',
  Payment: 'Payment',
  PaymentFailedContent_content_line1:
    'Unfortunately there was an error processing your purchase. Please check the payment details and try again.',
  PaymentFailedContent_content_line2:
    'If you need assistance, please contact us',
  PaymentFailedContent_cta: 'Try again',
  PaymentFailedContent_heading: 'Payment unsuccessful',
  Pending: 'Pending',
  Percent_of_total: '({total}% of total)',
  Phone: 'Phone',
  PhonePlanDetails_no_pin_number: 'No PIN code given',
  PhonePlanDetails_no_puk_number: 'No PUK code given',
  PhonePlanDetails_pin_number: 'PIN',
  PhonePlanDetails_provider_hint:
    'Tip: Phone Plans can only be cancelled by the employee through the respective provider.',
  PhonePlanDetails_puk_number: 'PUK',
  PhonePlanListContent_header__employee: 'Employee',
  PhonePlanListContent_header__name: 'Name',
  PhonePlanListContent_header__phone_number: 'Phone number',
  PhonePlanListContent_header__start_date: 'Start date',
  Phone_number: 'Phone number',
  Phone_plan: 'Phone plan',
  Phone_plan_details: 'Phone plan details',
  Phone_plans: 'Phone plans',
  PleaseTryAgainLater: 'Please try again later.',
  PortalLogin_accountLocked:
    '3 failed login attempts. Please wait 5 minutes before you try again.',
  PortalLogin_description:
    "The all-in-one solution to manage your company's mobile devices effortlessly",
  PortalLogin_header: 'Welcome',
  PortalLogin_header_to: 'Welcome to {name}',
  PortalLogin_title: 'Work smarter with Everphone',
  Postcode: 'Postcode',
  Preparing: 'Preparing',
  PreviewColumn_copayment_amount: 'Copayment amount',
  PreviewColumn_cost_center: 'Cost center',
  PreviewColumn_created: 'Created',
  PreviewColumn_delivery_status: 'Delivery status',
  PreviewColumn_eid: 'EID',
  PreviewColumn_email: 'Email',
  PreviewColumn_employee: 'Employee',
  PreviewColumn_employee_id: 'Employee ID',
  PreviewColumn_footer_button: 'See all details',
  PreviewColumn_footer_question: 'Ask a question',
  PreviewColumn_imei_1: 'IMEI',
  PreviewColumn_imei_2: 'IMEI 2',
  PreviewColumn_intercom_intro: 'I have a question regarding {type} ID: {id}: ',
  PreviewColumn_min_term: 'Minimum term',
  PreviewColumn_monthly_amount: 'Monthly amount',
  PreviewColumn_months:
    '{total} {total, plural, =0 {months} one {month} other {months}}',
  PreviewColumn_office: 'Office',
  PreviewColumn_organisation: 'Organisation',
  PreviewColumn_phone_number: 'Phone number',
  PreviewColumn_reason: 'Reason',
  PreviewColumn_request_date: 'Request date',
  PreviewColumn_request_id: 'Request ID',
  PreviewColumn_return_completed: 'Return completed',
  PreviewColumn_return_date: 'Return date',
  PreviewColumn_return_id: 'Return ID',
  PreviewColumn_serial: 'Serial number',
  PreviewColumn_sim_card_number: 'SIM card number',
  PreviewColumn_start: 'Start date',
  PreviewColumn_start_of_rental: 'Start of rental',
  PreviewColumn_title_preview: '{columnType} preview',
  PreviewColumn_type_employee: 'employee',
  PreviewColumn_type_phone_plan: 'phone plan',
  PreviewColumn_type_rental: 'rental',
  PreviewColumn_type_request: 'request',
  PreviewColumn_upgrade_date: 'Upgrade date',
  Price: 'Price',
  Processing: 'Processing',
  ProgressHeader_title: 'Step {step}: {title}',
  ProlongConfirmation_device: 'Device',
  ProlongConfirmation_heading: 'Rental period extended',
  ProlongConfirmation_new_end_date: 'Prolonged until',
  ProlongConfirmation_new_price: 'Price',
  ProlongConfirmation_subheading:
    'Thank you for contributing towards a sustainable future!  ',
  ProlongConfirmation_to_dashboard: 'Back to dashboard',
  ProlongConfirmation_total_amount: 'Monthly copayment (excl. VAT)',
  ProlongationSummary_accept:
    'I hereby accept the terms and conditions of the rent extension.',
  Prolongation_valid_till: 'Rental extended until',
  Prolonged_rentals: 'Prolonged rentals',
  PurchaseListContent_header__date: 'Date',
  PurchaseListContent_header__name: 'Name',
  PurchaseListContent_header__status: 'Status',
  PurchaseMyDevice: 'I would like to purchase my device',
  PurchaseSuccessfulContent_content_line1:
    'Thank you for your purchase. Your confirmation email will be sent to you shortly.',
  PurchaseSuccessfulContent_content_line2: 'We hope you enjoy your device.',
  PurchaseSuccessfulContent_content_line3:
    'If you do not receive a confirmation email, please contact us for further assistance.',
  PurchaseSuccessfulContent_heading: 'Purchase complete!',
  Purchase_device: 'Purchase device',
  Purchases: 'Purchases',
  PutEmployeeModal_allow_login: 'Allow user to login',
  PutEmployeeModal_allow_login_send_email:
    'Allow user to login and send welcome email',
  PutEmployeeModal_save_changes: 'Save changes',
  QRCodeCard_heading: 'Scan this QR code',
  Quantity: 'Quantity',
  QuestionDeliveryTime: 'Question about delivery time',
  QuestionDeviceManagement: 'Question about Mobile Device Management',
  QuestionReplacingDevice: 'Question about replacing a defective device',
  RangeInput_aria_label: 'Slide to select value(s) between {min} and {max}',
  RangeInput_max_value: 'Maximum value',
  RangeInput_min_value: 'Minimum value',
  RangeInput_selectedValue: 'Selected value',
  RangeInput_value: 'Value',
  Reason: 'Reason',
  ReassignDevice_Button: 'Reassign device',
  ReassignDevice_ModalSearchEmployee: 'Select new employee',
  ReassignDevice_ModalTitle: 'Reassign device',
  ReassignDevice_SubmissionError: 'Something went wrong. Please try again',
  ReassignDevice_SubmissionSuccess:
    'Rental has been successfully reassigned to {employeeName}',
  Reassign_device: 'Reassign device',
  Received: 'Received',
  Recent_requests: 'Recent requests',
  Recent_shipments: 'Recent shipments',
  Refresh_data: 'Refresh data',
  Refresh_the_page: 'Refresh the page',
  Reject: 'Reject',
  Rejected: 'Rejected',
  RentCategoryTier: 'Rent category tier',
  Rent_category_tier: 'Rent category tier',
  Rent_new_device: 'Rent new device',
  Rental: 'Rental',
  RentalDetails_alert_cancel:
    'A request to cancel this rental is being processed. You can check the status of request',
  RentalDetails_alert_lost_device:
    'This device is reported as lost or stolen. You can check the status of request',
  RentalDetails_alert_prolonging:
    'Extend your rental period for a lower monthly cost or upgrade to a new device for a new rental period.',
  RentalDetails_alert_repair:
    'This device is reported as broken. You can check the status of request',
  RentalDetails_alert_subhead: ' ',
  RentalDetails_alert_upgrade:
    'You can now upgrade to a new device. This will start a new rental with a new duration.',
  RentalDetails_check_status: 'here',
  RentalDetails_copayment: 'Monthly copayment',
  RentalDetails_eid: 'EID',
  RentalDetails_imei: 'IMEI',
  RentalDetails_imei2: 'IMEI2',
  RentalDetails_minimum_term: 'Minimum term',
  RentalDetails_monthly_amount: 'Monthly rent',
  RentalDetails_months: '{total} months',
  RentalDetails_select_a_new: 'Select a new model for a free upgrade after',
  RentalDetails_serial: 'Serial number',
  RentalDetails_start_of_rental: 'Start of rental',
  RentalDetails_you_can_now:
    'You can now upgrade to a new device of your choice by paying a one time early-upgrade fee.',
  RentalDetails_your_rental: 'Your rental',
  RentalDuration: 'Minimum rental duration: {total} months',
  RentalEntityCard_rental_id: 'Rental ID: {id}',
  RentalProlongingButton_additional_12_months:
    'Additional 12 months of service, including fast device exchange',
  RentalProlongingButton_extend_your_rental:
    'Click here to extend your rental and protect the environment',
  RentalProlongingButton_plant_trees:
    'For each extension, Everphone plants 30 trees',
  RentalProlongingButton_save_50:
    'Click here to save up to 50% by extending your rental and protect the environment',
  RentalProlonging_not_eligible:
    'This rental is not currently eligible to be extended.',
  Rental_details: 'Rental details',
  Rental_prolonging_description:
    'You can now extend the lease of your current device & save money. Everphone supports sustainability and plants 30 (!) trees for each extension. In addition, depending on your plan, you can save up to 50% of your co-payment. Alternatively, you can choose a new device.',
  Rental_prolonging_info_title: 'Extend rent or choose a new device',
  Rental_prolonging_prolong_upgrade: 'Choose a new device',
  Rentals: 'Rentals',
  RepairUpgrade_info_header: 'Repair Upgrade',
  RepairUpgrade_info_text:
    'Unfortunately, your device does not qualify for repair. However, you have the option to upgrade to a newer model of your choice. This will begin a new rental period.',
  Repair_upgrade: 'Repair upgrade',
  ReplaceBrokenDeviceModal_header: 'Report broken device',
  ReplaceBrokenDeviceModal_noReplacementFound:
    'No matching device found as replacement in your depot(s)',
  ReplaceBrokenDeviceModal_orderDepot: 'Order replacement from depot',
  ReplaceBrokenDeviceModal_orderEverphone: 'Order replacement from Everphone *',
  ReplaceBrokenDeviceModal_replacementModelNote:
    '* Same or similar model will be sent as replacement.',
  Replace_broken_device: 'Replace broken device',
  Replace_from_depot: 'Replace from depot',
  Replacement_device: 'Replacement device',
  Request: 'Request',
  RequestDetails_Track_Order: 'Track your order',
  RequestDetails_agent: 'Agent',
  RequestDetails_billing_identifier: 'Billing identifier',
  RequestDetails_custom_client_reference: 'Custom client reference',
  RequestDetails_customerLabel: 'Customer label details',
  RequestDetails_date: 'Date',
  RequestDetails_device: 'Device',
  RequestDetails_employee: 'Employee',
  RequestDetails_monthly_copayment: 'Monthly co-payment',
  RequestDetails_po_rcn: 'PO/RCN',
  RequestDetails_rent_category: 'Rent category',
  RequestDetails_request_id: 'Request ID',
  RequestListContent_header__agent: 'Agent',
  RequestListContent_header__date: 'Date',
  RequestListContent_header__employee: 'Employee',
  RequestListContent_header__status: 'Status',
  RequestListContent_header__title: 'Request',
  RequestPurchaseModal_confirmationDescription:
    'We would like to offer you the best possible purchase price. Our team will send you an offer by email shortly.',
  RequestPurchaseModal_confirmationHeader: 'Purchase device',
  RequestPurchaseModal_confirmationTitle:
    'Thank you for interest in purchasing the device.',
  RequestPurchaseModal_invalidDescription:
    'Please contact your administrator for return of the device.',
  RequestPurchaseModal_invalidHeader: 'Purchase device',
  RequestPurchaseModal_invalidTitle:
    'Unfortunately, this device is not eligible to be purchased.',
  RequestPurchaseModal_landingCheckbox:
    'Are there any visible damages or performance issues with the device?',
  RequestPurchaseModal_landingDescription:
    'To provide you with the best offer, please provide some information about your device. Please verify that device modal, serial number and IMEI(s) are matching with your device.',
  RequestPurchaseModal_landingHeader: 'Purchase device',
  RequestPurchaseModal_landingTitle:
    'Thank you for your interest in purchasing the device.',
  RequestPurchaseModal_summaryHeader: 'Purchase device - summary',
  Request_id: 'Request ID',
  Required_fields: '* Required fields',
  Reset: 'Reset',
  Reset_password: 'Reset Password',
  Reset_password_content:
    'To reset your password, please enter your company e-mail address. We will send the reset instructions to change your password.',
  Reset_password_header: 'Reset your password',
  Resolved_unreturned: 'Resolved unreturned',
  Results: 'Results',
  Return: 'Return',
  ReturnDetails_created: 'Created',
  ReturnDetails_device: 'Device',
  ReturnDetails_employee: 'Employee',
  ReturnDetails_imei: 'IMEI',
  ReturnDetails_imei2: 'IMEI2',
  ReturnDetails_reason_for_return: 'Reason for return',
  ReturnDetails_return_completed: 'Return completed',
  ReturnDetails_return_date: 'Return date',
  ReturnDetails_serial_number: 'Serial number',
  ReturnDetails_start_of_rental: 'Start of rental',
  ReturnDetails_your_return: 'Your return',
  ReturnDeviceModal_no_depots: 'No return-depots found',
  ReturnListContent_header__created: 'Created',
  ReturnListContent_header__employee: 'Employee',
  ReturnListContent_header__status: 'Status',
  ReturnListContent_header__title: 'Return',
  Return_depot: 'Return depot',
  Return_details: 'Return details',
  Return_device: 'Return device',
  Return_id: 'Return ID',
  Returns: 'Returns',
  RouteGuard_continue: 'Continue',
  RouteGuard_heading: 'Are you sure you want to leave?',
  RouteGuard_leave_page: 'Leave page',
  RouteGuard_subheading:
    'By leaving the page, any changes you have made will be lost.',
  SSOButton_Google_label: 'Sign in with Google',
  SSOButton_Microsoft_label: 'Sign in with Microsoft',
  Save: 'Save',
  Search: 'Search',
  Security: 'Security',
  See_all: 'See all',
  See_delivery_status: 'Track and trace',
  See_more: 'See more',
  Select_country: 'Select a country',
  Select_org_or_employee: 'Select organisation or employee',
  Select_placeholder: 'Select...',
  SelectionCard_effective_price: '** Effective price',
  SelectionCard_monthly_copayment: 'Monthly copayment',
  SelectionCard_monthly_price: 'Monthly price',
  Sender_country: 'Sender country',
  Serial_number: 'Serial number',
  ServiceApp_show_details: 'Show Details',
  ShipmentTrackingCard_delivery_status: 'Delivery status',
  ShipmentTrackingCard_shipping: 'Shipping',
  ShipmentTrackingCard_shipping_address: 'Shipping address',
  ShipmentTrackingCard_track_and_trace: 'Track and trace',
  ShipmentTrackingCard_track_order: 'Track your order',
  ShipmentTrackingCard_track_return: 'Track your return',
  ShippedOrdersCard_heading: 'Track your shipped orders',
  ShippingAddressForm_heading: 'What is the shipping address?',
  Shipping_address: 'Shipping address',
  Sim_card_number: 'Sim card number',
  Sitemap: 'Sitemap',
  Sitemap_additional_pages: 'Additional Pages',
  Sitemap_main_sections: 'Main sections',
  Sold: 'Sold',
  Start: 'Start',
  Start_date: 'Start date',
  State: 'State',
  Status: 'Status',
  Street_addition: 'Street addition',
  Street_name: 'Street name',
  Street_name_and_number: 'Street number and name',
  Street_number: 'Street number',
  Submit: 'Submit',
  Success: 'Success',
  Summary: 'Summary',
  TableCard_total:
    '{amount} {amount, plural, =0 {devices} =1 {device} other {devices}} in total',
  TechnicalIssues: 'Technical issues',
  Terms: 'Terms',
  To: 'To',
  Total: 'Total',
  TotalCounter_total: 'of',
  Total_last_30_days: 'Total in the last 30 days',
  Total_orders:
    '{total} {total, plural, =0 {total orders} =1 {total order} other {total orders}}',
  Tracking_reference: 'Tracking reference',
  Unknown_case_type: 'Unknown case type',
  Unknown_contact: 'Unknown contact',
  Unnamed_phone_plan: 'Unnamed phone plan',
  UnreturnedDevicesCard_heading: 'Unreturned devices',
  Upcoming: 'Upcoming',
  Update_tracking_information: 'Update tracking information',
  Upgrade: 'Upgrade',
  Upgrade_Fee_ExclVAT: 'One time upgrade fee (excl. VAT)',
  Upgrade_device: 'Upgrade device',
  Upgrade_possible: 'Upgrade possible',
  Username: 'Username',
  VAT: 'VAT',
  Valid_from_date: 'Valid from date',
  View_orders: 'View orders',
  View_rentals: 'View rentals',
  Waiting: 'Waiting',
  Yes: 'Yes',
  YourRequestHasBeenSubmitted: 'Your request has been submitted.',
  Zip: 'ZIP',
  for: 'for',
  typesenseAttribute_case_item_copayment: 'Copayment',
  typesenseAttribute_case_item_created: 'Date',
  typesenseAttribute_case_item_device_definition_manufacturer: 'Manufacturer',
  typesenseAttribute_case_item_device_definition_name: 'Model',
  typesenseAttribute_case_item_device_definition_type: 'Device type',
  typesenseAttribute_case_item_employee: 'Employee',
  typesenseAttribute_case_item_status: 'Order status',
  typesenseAttribute_case_item_type: 'Order type',
  typesenseAttribute_employee_cost_center_name: 'Cost center',
  typesenseAttribute_employee_email: 'Email address',
  typesenseAttribute_employee_exit_date: 'Exit date',
  typesenseAttribute_employee_external_reference:
    'Employee ID (External reference)',
  typesenseAttribute_employee_full_name: 'Employee',
  typesenseAttribute_employee_has_active_rental: 'Active Rental',
  typesenseAttribute_employee_office: 'Office',
  typesenseAttribute_employee_phone_number: 'Phone number',
  typesenseAttribute_employee_rent_category_tier: 'Rent category tier',
  typesenseAttribute_rental_employee_exit_date: 'Employee exit date',
  typesenseAttribute_rental_end_at: 'Rental end date',
  typesenseAttribute_rental_is_prolonged: 'Is prolonged',
  typesenseAttribute_rental_minimum_contract_duration:
    'Minimum contract duration',
  typesenseAttribute_rental_rental_price_copayment_amount: 'Copayment',
  typesenseAttribute_rental_start_at: 'Rental start date',
  typesenseAttribute_rental_stocklist_item_codename: 'Model',
  typesenseAttribute_rental_stocklist_item_manufacturer: 'Manufacturer',
  typesenseAttribute_rental_upgrade_date: 'Upgrade eligible from',
  typesenseAttribute_return_case_created: 'Return created',
  typesenseAttribute_return_case_employee_exit_date: 'Employee exit date',
  typesenseAttribute_return_case_employee_office: 'Office',
  typesenseAttribute_return_case_open_since: 'Awaiting return since',
  typesenseAttribute_return_case_rental_cost_center: 'Cost center',
  typesenseAttribute_return_case_return_depot_name: 'Return depot',
  typesenseAttribute_return_case_status: 'Return case status',
  typesenseAttribute_return_case_stocklist_item_codename: 'Model',
  typesenseAttribute_return_case_stocklist_item_manufacturer: 'Manufacturer',
  typesenseAttribute_unknown_attribute: 'Unknown attribute',
  ...dataPrivacyTranslations,
  ...backendEnTranslations,
}

export default en
