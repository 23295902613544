import { FormattedMessage, useIntl } from 'react-intl'
import { faEnvelope, faSquarePhone } from '@fortawesome/pro-solid-svg-icons'

import { Heading, LinkButton } from 'Components/_theme'
import { Icon } from 'Components/Icon/Icon'

export const ContactLandingDetails = () => {
  const intl = useIntl()

  return (
    <>
      <div className="ContactLandingDetails">
        <Heading as={3} className="mb-4" size={2}>
          <FormattedMessage id="ContactDetails_header" />
        </Heading>

        <p className="text-center mb-9">
          <FormattedMessage id="ContactDetails_description" />
        </p>

        <a
          className="d-none d-xl-flex align-items-center mb-6"
          href="mailto:service@everphone.de"
          title={intl.formatMessage({ id: 'Email' })}
        >
          <Icon className="me-2" icon={faEnvelope} /> service@everphone.de
        </a>

        <a
          className="d-none d-xl-flex align-items-center mb-9"
          href="tel:4930516958275"
          title={intl.formatMessage({ id: 'Phone' })}
        >
          <Icon className="me-2" icon={faSquarePhone} /> +49 30 516958275
        </a>

        <div className="d-flex d-xl-none mb-9">
          <LinkButton
            asExternalLink
            className="me-2"
            iconLeft={faEnvelope}
            title={intl.formatMessage({ id: 'Email' })}
            to="mailto:service@everphone.de"
          >
            <FormattedMessage id="Email" />
          </LinkButton>

          <LinkButton
            asExternalLink
            iconLeft={faSquarePhone}
            title={intl.formatMessage({ id: 'Phone' })}
            to="tel:4930516958275"
          >
            <FormattedMessage id="Phone" />
          </LinkButton>
        </div>

        <div className="text-center mb-xl-0">
          <FormattedMessage id="ContactDetails_openingHours" />
          <br />
          <FormattedMessage id="ContactDetails_service" />
        </div>
      </div>

      {/* <div className="d-block d-xl-none">
        <EmployeeTile isLanding />
      </div> */}
    </>
  )
}
