import { observer } from 'mobx-react'

import { useActiveUser } from 'Hooks'

import { InstantsearchProvider } from 'Portal/src/providers'
import { InstantsearchIndexProvider } from 'Portal/src/providers/InstantsearchIndexProvider/InstantsearchIndexProvider'

import { ReturnsContent } from './Returns.content'

export const ReturnsInstantsearch = observer(() => {
  const { activeEmployee, activeOrganisation } = useActiveUser()
  const organisationId =
    activeEmployee?.organisation?.id ?? activeOrganisation!.id

  return (
    <InstantsearchIndexProvider indexName="return_cases">
      <InstantsearchProvider
        instantSearchConfig={{
          filters: `return_case.organisation_id:${organisationId} && return_case.created:>0 && return_case.stocklist_item.id:>0`,
        }}
      >
        <ReturnsContent />
      </InstantsearchProvider>
    </InstantsearchIndexProvider>
  )
})
